import { Logger } from "@/logger";
import type { DRViewer } from "../DRViewer";
import { type Attribute } from "./BlockBuilder";
import { EntityBuilder, type EntityProps } from "./EntityBuilder";
import OdaGeometryUtils from "./odaGeometry.utils";
import { TextBuilder } from "./TextBuilder";
import Toolbox from "./ODAToolbox";

export class InsertBuilder {
  viewer: DRViewer;

  constructor(viewer: DRViewer) {
    this.viewer = viewer;
  }

  static setBlockMatrix(
    entId: VisualizeJS.OdTvEntityId,
    matrix: VisualizeJS.Matrix3d
  ) {
    const insert = entId.openObjectAsInsert();
    const old = insert.getBlockTransform();
    old.invert();
    const delta = old.preMultBy(matrix);
    insert.setBlockTransform(matrix);
    const itr = insert.getSubEntitiesIterator();
    for (; !itr.done(); itr.step()) {
      const entId = itr.getEntity();
      const baseMatrix = EntityBuilder.getModelingMatrix(entId);
      baseMatrix.preMultBy(delta);
      EntityBuilder.setModelingMatrix(entId, baseMatrix);
      baseMatrix.delete();
      entId.delete();
    }
    itr.delete();
    delta.delete();
    old.delete();
  }

  static getBlockMatrix(entId: VisualizeJS.OdTvEntityId): VisualizeJS.Matrix3d {
    const insert = entId.openObjectAsInsert();
    const matrix = insert.getBlockTransform();
    insert.delete();
    return matrix;
  }

  static addAttribute(
    insertId: VisualizeJS.OdTvEntityId,
    attribute: Attribute,
    message: string
  ) {
    const insert = insertId.openObjectAsInsert();
    const entId = insert.appendSubEntity(attribute.name);
    TextBuilder.addText(entId, { ...attribute.data, message });
    insert.delete();
    entId.delete();
  }

  // does not work at the moment
  static setUniformScaleFactor(entId: VisualizeJS.OdTvEntityId, scale: number) {
    const insert = entId.openObjectAsInsert();
    insert.setScaleFactors([scale, scale, 1]);
    insert.delete();
  }

  static scaleInsert(entId: VisualizeJS.OdTvEntityId, scale: number) {
    const initialScale = 1;
    const oldMatrix = EntityBuilder.getModelingMatrix(entId);
    const newMatrix = OdaGeometryUtils.reScaleMatrix(
      oldMatrix,
      scale,
      initialScale
    );
    EntityBuilder.setModelingMatrix(entId, newMatrix);
  }

  static isInsert(entId: VisualizeJS.OdTvEntityId) {
    return entId.getType() === 2;
  }

  getSubEntityTextProps(
    insertId: VisualizeJS.OdTvEntityId
  ): Required<Pick<EntityProps, "textsize" | "textstyle">>[] {
    if (insertId.getType() === 2) {
      const insert = insertId.openObjectAsInsert();
      const itr = insert.getSubEntitiesIterator();
      const textProps = [];
      for (; !itr.done(); itr.step()) {
        const subentId = itr.getEntity();
        textProps.push(...this.viewer.entityBuilder.getTextProps(subentId));
        subentId.delete();
      }
      Toolbox.deleteAll([itr, insert]);
      return textProps;
    } else {
      Logger.warn(`insertBuilder.getSubEntityTextProps : entity type is not 2`);
      return [];
    }
  }

  getScale(insertId: VisualizeJS.OdTvEntityId): number | undefined {
    if (insertId.getType() === 2) {
      const insert = insertId.openObjectAsInsert();
      const scale = insert.getScaleFactors();
      insert.delete();
      return scale[0];
    } else {
      Logger.warn(`InsertBuilder.computeScale : entity is not of type 2`);
      return undefined;
    }
  }
}
