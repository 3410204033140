import { reactive, toRaw } from "vue";
import type { StoredSettings } from "./CanvasSettingsState";
import type { BlockConfig } from "@/open-cloud/builders/BlockBuilder";
import BlockLibrary from "@/blocks/library";
import { Facade } from "@/open-cloud/Facade";
import _ from "lodash";
import CanvasSettingsState from "./CanvasSettingsState";
type DraggerOptionState = {
  storedSettings: StoredSettings | null;
  activeBlock: BlockConfig | null;
  initialize: (storedSettings: StoredSettings | null) => void;
  setActiveBlock: (name: string) => void;
  onDrawingOpen: () => void;
};

export default reactive<DraggerOptionState>({
  storedSettings: null,
  activeBlock: null,
  initialize(storedSettings: StoredSettings | null) {
    this.storedSettings = storedSettings;
    if (storedSettings?.dragger?.activeBlock) {
      this.activeBlock =
        BlockLibrary.getByName(storedSettings?.dragger?.activeBlock.name) ||
        null;
    }
  },
  onDrawingOpen() {
    if (this.activeBlock) {
      this.setActiveBlock(this.activeBlock.name);
    } else {
      this.setActiveBlock("");
    }
  },
  setActiveBlock(name) {
    this.activeBlock = BlockLibrary.getByName(name) || null;
    Facade.setActiveBlock(toRaw(this.activeBlock));
    this.storedSettings = _.set(
      this.storedSettings || {},
      "dragger.activeBlock.name",
      this.activeBlock?.name
    );
    CanvasSettingsState.updateAndPersist();
  },
});
