import { DRViewer } from "./DRViewer";

export default class LibSingleton {
  private static _lib: typeof VisualizeJS | null = null;
  private static _viewer: typeof VisualizeJS | null = null;

  static get lib() {
    if (this._lib) return LibSingleton._lib;
    else throw new Error(`LibSingleton : no lib`);
  }

  static get viewer() {
    if (this._viewer) return LibSingleton._viewer;
    else throw new Error(`LibSingleton : no viewer`);
  }

  static init(viewer: DRViewer) {
    LibSingleton._lib = viewer.visLib();
    LibSingleton._viewer = viewer.visViewer();
  }
}
