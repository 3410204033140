import type { BlockConfig } from "@/open-cloud/builders/BlockBuilder";
import { DR_BLOCKS } from "./block-list";
import { cloneDeep } from "lodash";

export default class BlockLibrary {
  // Returns a clone of the hatch pattern
  static getByName(name: string): BlockConfig | undefined {
    //return undefined;
    for (const block of DR_BLOCKS) {
      if (block.name === name) {
        const clone = cloneDeep(block);
        return clone;
      }
    }
  }

  static getAllNames(): string[] {
    return DR_BLOCKS.map((block) => block.name);
  }

  // angle in radian
  static rotateVector(
    v: [x: number, y: number],
    angle: number
  ): [x: number, y: number] {
    return [
      v[0] * Math.cos(angle) - v[1] * Math.sin(angle),
      v[0] * Math.sin(angle) + v[1] * Math.cos(angle),
    ];
  }
}
