<script setup lang="ts">
import ScaleMenuState from "@/stores/ScaleMenuState";
import ScaleItem from "../Settings/ScaleItem.vue";
import { computed, ref, toRaw } from "vue";
import type { ExposedScaleMenu } from "./floating.type";
import { Facade } from "@/open-cloud/Facade";
import type { NoteConfig } from "@/stores/UserState";
import { Logger } from "@/logger";
import type { EntityProps } from "@/open-cloud/builders/EntityBuilder";
import { cloneDeep } from "lodash";

const size = ref<number>();
const input = ref<number>();
const handle = ref("");

function update(targets: string[]) {
  if (targets.length === 0) {
    handle.value = "";
    size.value = 0;
  } else {
    handle.value = targets[0];
    size.value = Facade.getSelectedEntityScale(handle.value);
  }
}

function insertAndSetProps(config: NoteConfig) {
  Logger.info(`ScaleDropdown : insert and set props`);
  if (config.props.textstyle) {
    Facade.updateOrCreateTextStyle(toRaw(config.props.textstyle));
    setProps(config);
  }
}

function setProps(config: NoteConfig): void {
  Logger.info(`ScaleDropdown : set props to ${JSON.stringify(config)}`);
  const props: EntityProps = cloneDeep(toRaw(config.props));
  // 0 means use textstyle for open cloud
  if (!props.textsize) props.textsize = 0;
  Facade.setSelectionProperty(props);
}

function setHeight() {
  Logger.info(`ScaleDropdown : set height to ${input.value}`);
  setProps({
    name: "",
    props: {
      textsize: input.value,
    },
  });
}

defineExpose<ExposedScaleMenu>({
  update,
});

const show = computed<boolean>(() => {
  return Facade.isScalable(handle.value);
});
</script>
<template>
  <div v-show="show" class="btn-group" role="group">
    <button
      type="button"
      class="btn btn-secondary dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      ref="refHook"
      data-bs-content="Appliquez des styles de textes (Arial, Times, ...) et des échelles à vos textes."
      data-bs-title="Echelle et style"
      @click="ScaleMenuState.updateScales()"
    >
      h({{ size }})
    </button>
    <ul
      class="dropdown-menu dropdown-menu-dark overflow-y-auto"
      style="max-height: 45vh"
    >
      <li>
        <div class="input-group p-1">
          <input
            type="number"
            class="form-control"
            v-model="input"
            style="width: 50px"
          />
          <span
            type="button"
            class="input-group-text"
            aria-expanded="false"
            @click="setHeight()"
          >
            <i class="bi bi-check" />
          </span>
        </div>
      </li>
      <li>
        <h6 class="dropdown-header">Vos styles à insérer</h6>
      </li>
      <li
        v-for="scale in ScaleMenuState.scales[1]"
        :key="scale.name"
        @click="() => insertAndSetProps(scale)"
      >
        <ScaleItem :scale="scale" />
      </li>
      <li>
        <hr class="dropdown-divider" />
      </li>
      <li>
        <h6 class="dropdown-header">Dans le dessin</h6>
      </li>
      <li
        v-for="scale in ScaleMenuState.scales[0]"
        :key="scale.name"
        @click="() => setProps(scale)"
      >
        <ScaleItem :scale="scale" />
      </li>
    </ul>
  </div>
</template>
