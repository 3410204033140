import { EntityBuilder } from "../builders/EntityBuilder";
import Toolbox from "../builders/ODAToolbox";
import Command from "./Command";

export type AddLeaderParams = {
  handle: string;
  tipPoint: VisualizeJS.Point3;
}[];

export default class AddLeader extends Command<AddLeaderParams> {
  // Takes entity in temp layer and re-add it by setting back the layer
  _reexecute(params: AddLeaderParams) {
    for (const param of params) {
      const entId = this._viewer.modelBuilder.getEntityByHandle(param.handle);
      if (entId) {
        let scale = 1;
        const propsArray = this._viewer.entityBuilder.getTextProps(entId);
        if (propsArray.length) {
          scale = EntityBuilder.computeScaleFromProps(propsArray[0]) || 0;
        }
        this._viewer.leaderBuilder.addOrUpdateLeader(
          entId,
          param.tipPoint,
          scale
        );
      }
    }
    this._viewer.update();
  }
  // move entity in Temp layer
  _unexecute(params: AddLeaderParams) {
    for (const param of params) {
      const entId = this._viewer.modelBuilder.getEntityByHandle(param.handle);
      if (entId && !entId.isNull()) {
        const ent = entId?.openObject();
        const itr = ent.getGeometryDataIterator();
        while (!itr.done()) {
          const geomId = itr.getGeometryData();
          itr.step();
          if (itr.done()) {
            ent.removeGeometryData(geomId);
          }
          geomId.delete();
        }
        Toolbox.deleteAll([ent, itr, entId]);
      }
    }
    this._viewer.update();
  }
}
