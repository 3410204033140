import type { DRViewer } from "@/open-cloud/DRViewer";
import { DraggerEvents } from "@/open-cloud/draggers/draggers.type";
import { debounce } from "lodash";
import Grid from "./Grid";
import Toolbox from "@/open-cloud/builders/ODAToolbox";
import OdaGeometryUtils from "@/open-cloud/builders/odaGeometry.utils";
import { EntityBuilder } from "../../builders/EntityBuilder";
import DraggerState from "../DraggersState";
import { Logger } from "@/logger";
import { DrawSelectionGridEvent, type HandleName } from "./transform.type";
import { ModelBuilder } from "@/open-cloud/builders/ModelBuilder";
import {
  computeConstraintResize,
  computeFreeResize,
  computeRotation,
  computeTranslation,
} from "./transform.utils";
import type { AddLeaderParams } from "@/open-cloud/commands/AddLeader";

/**
 * We call _Grid_ the grid around a selected entity along with its _Handles_ (colored circles user can grad to rotate/scale/translate).
 * While the user is rotating or resizing an entity, we display what would be the result, we called that temporary entity the _Ghost_ entity.
 */
export class Transformer {
  grids: Grid[] = [];
  // the handle the user is currently dragging
  activeHandle?: HandleName;
  baseMatrix: VisualizeJS.Matrix3d | null = null;
  // temporary entity that shows the user how will look the target entity when the transformation ends.
  // Is deleted when dragging ends
  ghostEntity: VisualizeJS.OdTvEntityId | null = null;

  leaderHandle: string | undefined = undefined;
  leaderScale = 0;

  pointerDownEvent: PointerEvent | null = null;

  private canvas: HTMLElement;

  handlersToRemove: {
    target: HTMLElement;
    type: string;
    handler: (...args: any[]) => unknown;
  }[] = [];

  constructor(
    canvas: HTMLElement,
    private readonly viewer: DRViewer,
    private readonly toolbox: Toolbox
  ) {
    this.canvas = canvas;
    viewer.addEventListener(DraggerEvents.AutoPanZoomEnded, () => {
      Logger.info(`transformer.ts : auto pan event`);
      this.handleAutoPanEnded();
    });

    viewer.addEventListener(DraggerEvents.AutoPanZoomStart, () => {
      Logger.info(`transformer.ts : auto pan event`);
      this.cleanGrids();
      this.updateFloatingMenu([]);
    });

    viewer.addEventListener("zoomat", () => {
      Logger.info(`transformer.ts : zoom at event`);
      this.debouncedRedrawGrid();
    });

    viewer.addEventListener(DraggerEvents.UnselectAll, () => {
      this.drawGridIOnSelection();
    });

    viewer.addEventListener(DraggerEvents.SetSelectionProperty, () => {
      this.drawGridIOnSelection();
    });

    viewer.addEventListener(DraggerEvents.SelectionDeleted, () => {
      this.drawGridIOnSelection();
    });

    viewer.addEventListener(DraggerEvents.SelectionEnded, () => {
      Logger.info(`transformer.ts : selection end event`);
      this.drawGridIOnSelection();
    });

    this.startListeningForTransform();
  }
  get visLib(): typeof VisualizeJS {
    return this.viewer.visLib();
  }
  get visViewer(): VisualizeJS.Viewer {
    return this.viewer.visViewer();
  }

  get mupModel(): VisualizeJS.TvModel {
    const viewer = this.visViewer;
    return viewer.getMarkupModel();
  }

  dispose() {
    this.handlersToRemove.forEach(({ target, type, handler }) => {
      target.removeEventListener(type, handler);
    });
    this.cleanGrids();
    this.activeHandle = undefined;
    this.ghostEntity?.delete();
    this.ghostEntity = null;
    this.pointerDownEvent = null;
    this.baseMatrix = null;
  }

  startListeningForTransform() {
    Logger.info("transformer.ts : start listening for transform");
    this.canvas.addEventListener(
      "pointerdown",
      (event: PointerEvent) => {
        // if we have a resize grid diplayed, we need to check if the click is on one of the dragging buttons/circles
        this.handleTransformStart(event);
      },
      {
        passive: false,
      }
    );

    this.canvas.addEventListener(
      "pointermove",
      (event: PointerEvent) => {
        if (
          this.pointerDownEvent &&
          event.pointerId === this.pointerDownEvent.pointerId
        ) {
          try {
            this.handleTransformOngoing(event);
          } catch (e) {
            Logger.error(
              `transformer.handleTransformOngoing : e : ${JSON.stringify(
                e
              )}, grids: ${JSON.stringify(
                this.grids.map((grid) => grid.handles)
              )}, activeHandle: ${JSON.stringify(this.activeHandle)}`
            );
          }
          try {
            this.handleTransformOngoing(event);
          } catch (e) {
            if (e instanceof TypeError) {
              Logger.error(
                `transormer.handleTransformOngoing : ${
                  e.message
                }, grids: ${JSON.stringify(
                  this.grids.map((grid) => grid.handles)
                )}, activeHandle: ${JSON.stringify(this.activeHandle)}`
              );
            }
            throw e;
          }
        }
      },
      {
        passive: false,
      }
    );

    this.canvas.addEventListener("pointerup", (event: PointerEvent) => {
      if (
        this.pointerDownEvent &&
        event.pointerId === this.pointerDownEvent.pointerId
      ) {
        this.handleTransformationEnds(event);
        this.pointerDownEvent = null;
      }
    });
  }

  handleTransformStart(event: PointerEvent) {
    // if nothing is selected, we can safely ignore
    if (!this.grids.length) return;

    // if it's a touch event, we want only one finger, not supporting funky gestures for now
    // if we have a resize grid displayed, we need to check if the click is on one of the dragging buttons/circles
    const coordRel = Toolbox.eventToWorldCoord(event);
    const point = Toolbox.point2Coord(
      this.visViewer.screenToWorld(coordRel.x, coordRel.y)
    );

    // is the tap/click on a handle ?
    const matchHandle = this.matchHandle(point.x, point.y);
    if (!matchHandle) return;
    // one of the active handle was selected, then deactivate other tools
    DraggerState.isTransformActive = true;
    this.pointerDownEvent = event;
    this.activeHandle = matchHandle.handleName;

    const targetId = this.grids[0].targetEntityIds[0];
    const propsArray = this.viewer.entityBuilder.getTextProps(targetId);
    if (propsArray.length) {
      this.leaderScale = EntityBuilder.computeScaleFromProps(propsArray[0]) || 0;
    }
    this.baseMatrix = EntityBuilder.getModelingMatrix(targetId);
    Logger.info(
      `transformer.handleTransformStart : activehandle : ${this.activeHandle}`
    );
  }

  handleTransformOngoing(event: PointerEvent) {
    if (!this.activeHandle)
      throw new Error(
        "transformer.handleTransformationOngoing : no active handle"
      );

    // init ghost entity
    if (this.grids.length) {
      const grid = this.grids[0];
      const targetEntityId = this.grids[0].targetEntityIds[0];
      this.ghostEntity = this.safeInitGhostVisual(targetEntityId);

      // calculate coordinate of canvas in WCS
      const eventRelCoord = Toolbox.eventToWorldCoord(event);
      const eventWorldPoint = this.visViewer.screenToWorld(
        eventRelCoord.x,
        eventRelCoord.y
      );

      if (this.activeHandle === "rotation") {
        if (grid.handles.center && grid.handles.rotation) {
          const transformMatrix = computeRotation(
            eventWorldPoint,
            grid.handles.center.point,
            grid.handles.rotation.point
          );
          this.appendTranformToGhost(transformMatrix);
        }
      } else if (this.activeHandle === "center") {
        if (grid.handles.center) {
          const transformMatrix = computeTranslation(
            eventWorldPoint,
            grid.handles.center.point
          );
          this.appendTranformToGhost(transformMatrix);
        }
      } else if (this.activeHandle === "scale") {
        if (grid.handles.scale && grid.handles.topLeft) {
          const transformMatrix = computeConstraintResize(
            eventWorldPoint,
            grid.handles.scale.point,
            grid.handles.topLeft.point
          );
          this.appendTranformToGhost(transformMatrix);
        }
      } else if (this.activeHandle === "leader") {
        this.leaderHandle = this.viewer.leaderBuilder.addOrUpdateLeader(
          this.ghostEntity,
          eventWorldPoint,
          this.leaderScale,
          this.leaderHandle
        );
      } else {
        const scaleParameters = Grid.matchScaleHandler(this.activeHandle);
        if (!scaleParameters) return;
        const draggedHandle = grid.handles[this.activeHandle];
        const refHandle = grid.handles[scaleParameters.refHandle];
        if (!draggedHandle || !refHandle) return;
        const transformMatrix = computeFreeResize(
          eventWorldPoint,
          draggedHandle.point,
          refHandle.point,
          scaleParameters.axles
        );
        this.appendTranformToGhost(transformMatrix);
      }

      this.viewer.update();
    } else {
      Logger.warn(`tranformer.handleTransformOgoing : no grid`);
    }
  }

  appendTranformToGhost(transformMatrix: VisualizeJS.Matrix3d) {
    this.grids[0].data.transformMatrix?.delete();
    this.grids[0].data.transformMatrix = transformMatrix;
    if (this.baseMatrix && this.ghostEntity) {
      EntityBuilder.appendTransformWithBase(
        this.ghostEntity,
        transformMatrix,
        this.baseMatrix
      );
    }
  }

  safeInitGhostVisual(
    targetEntityId: VisualizeJS.OdTvEntityId
  ): VisualizeJS.OdTvEntityId {
    // if it's the first time we call, we create the Ghost entity in the markup model (not saved)
    if (!this.ghostEntity) {
      this.ghostEntity = ModelBuilder.cloneEntity(
        targetEntityId,
        this.mupModel
      );
    }
    // we hide the original entity, as we're going now to display the ghost entity
    this.toolbox.setVisible(targetEntityId, false);
    // @todo this is shortcut to not to have to redraw the Grid during the dragging, can be implemented in the future
    this.setGridVisible(false);
    return this.ghostEntity;
  }

  handleTransformationEnds(event: PointerEvent) {
    if (this.activeHandle) {
      Logger.info(
        `tranformer.handleTransformationEnds : done transforming, ${this.activeHandle}`
      );
      if (this.ghostEntity) {
        const targetGrid = this.grids[0];
        const targetId = this.grids[0].targetEntityIds[0];
        if (this.activeHandle != "leader") {
          // if a corner has been click but there is still no tempEntity,
          // it's probably that the user clicked on the button but didn't drag (simple click)
          const ghostMatrix = EntityBuilder.getModelingMatrix(this.ghostEntity);
          EntityBuilder.setModelingMatrix(targetId, ghostMatrix);
          ghostMatrix.delete();
          // for undo/redo
          if (targetGrid.data?.transformMatrix) {
            const vectors = OdaGeometryUtils.getMatrixCoordVectors(
              targetGrid.data?.transformMatrix
            );
            const params = {
              handle: EntityBuilder.getHandle(targetId),
              vectors: vectors,
            };
            this.viewer.commandFactory.transformEntities.execute([params]);
          } else {
            Logger.warn(
              `transformation ended but no transformation matrix found`
            );
          }
        } else {
          // calculate coordinate of canvas in WCS
          const eventRelCoord = Toolbox.eventToWorldCoord(event);
          const eventWorldPoint = this.visViewer.screenToWorld(
            eventRelCoord.x,
            eventRelCoord.y
          );
          this.viewer.leaderBuilder.addOrUpdateLeader(
            targetId,
            eventWorldPoint,
            this.leaderScale
          );

          const handle = EntityBuilder.getHandle(targetId);

          const params: AddLeaderParams = [
            {
              handle,
              tipPoint: eventWorldPoint,
            },
          ];
          this.viewer.commandFactory.addLeaders.execute(params);
        }

        const modelmup = this.mupModel;
        modelmup.removeEntity(this.ghostEntity);

        this.baseMatrix?.delete();
        this.baseMatrix = null;

        this.ghostEntity.delete();
        modelmup.delete();
        this.toolbox.setVisible(this.grids[0].targetEntityIds[0], true);
      }
      this.cleanGrids();
      this.activeHandle = undefined;
      this.leaderHandle = "";
      this.leaderScale = 0;
      this.ghostEntity = null;
      DraggerState.isTransformActive = false;
      this.drawGridIOnSelection();
      this.viewer.update();
    }
  }

  cleanGrids() {
    this.grids.forEach((grid) => {
      grid.clean();
    });
    this.grids = [];
  }

  // Hide and show all grids and the floating menu
  setGridVisible(visible: boolean) {
    this.grids.forEach((grid) => {
      grid.setVisible(visible);
      if (visible) {
        const handles = grid.targetEntityIds.map((entity) =>
          EntityBuilder.getHandle(entity)
        );
        this.updateFloatingMenu(handles);
      } else {
        this.updateFloatingMenu([]);
      }
    });
  }

  handleAutoPanEnded() {
    this.drawGridWithAnimationFrame();
  }

  /**
   * check if a given point matches a handle
   * @param x
   * @param y
   */
  matchHandle(
    x: number,
    y: number
  ): { grid: Grid; handleName: HandleName } | undefined {
    for (const grid of this.grids) {
      const handleName = grid.matchHandle(x, y);
      if (handleName) {
        return {
          ...handleName,
          grid,
        };
      }
    }
  }

  /**
   * Draw grid perimeter and grid handles
   */

  drawGridIOnSelection() {
    this.cleanGrids(); // avoids having many grid on top of each other
    if (this.viewer.selectionSet && this.viewer.selectionSet?.numItems() > 0) {
      const itr = this.viewer.selectionSet.getIterator();
      const entityId = itr.getEntity();
      const grid = new Grid([entityId], this.viewer);
      this.grids.push(grid);
      grid.draw();
      Logger.info(`transformer.drawGridIOnSelection`);
      this.updateFloatingMenu([EntityBuilder.getHandle(entityId)]);
      itr.delete();
    } else {
      this.updateFloatingMenu([]);
      Logger.warn(`transformer.ts : no entity in selectionset`);
    }
  }

  debouncedRedrawGrid = debounce(this.drawGridWithAnimationFrame, 100);

  /**
   * Use to re-draw the Grid after a zoom
   */
  drawGridWithAnimationFrame() {
    this.drawGridIOnSelection();
    this.viewer.update();
  }

  /**
   * Send event to update front menu
   * Sending [] hides it
   * @param targets handles of selected entities
   */
  updateFloatingMenu(handles: string[]) {
    document.dispatchEvent(
      new DrawSelectionGridEvent({
        targetHandles: handles,
      })
    );
  }
}
