export enum DraggerName {
  DrEllipse = "DrEllipse",
  DrRectangle = "DrRectangle",
  DrFreeLine = "DrFreeLine",
  DrStraightLine = "DrStraightLine",
  PhotoMarker = "PhotoMarker",
  DrPolyline = "DrPolyline",
  DrSelect = "DrSelect",
  DrText = "DrText",
  Identity = "Identity",
  DrOrigin = "DrOrigin",
  DrZoomToWindow = "DrZoomToWindow",
  NoDragger = "",
  DrBlock = "DrBlock",
}

export type DraggerConfig = {
  name: DraggerName;
};

export enum DraggerEvents {
  AutoPanZoomStart = "AutoPanZoomStart",
  AutoPanZoomEnded = "AutoPanZoomEnded",
  UnselectAll = "UnselectAll",
  SelectionDeleted = "SelectionDeleted",
  SelectionEnded = "SelectionEnded",
  SelectionDuplicated = "SelectionDuplicated",
  SetSelectionProperty = "SetSelectionProperty",
  OriginSet = "OriginSet",
  Escape = "Escape",
  PhotoMarkerAdded = "PhotoMarkerAdded",
}

export type Coords = { x: number; y: number; z?: number };
