import log from "loglevel";
import * as Sentry from "@sentry/browser";
import UserState from "./stores/UserState";
import { CURRENT_VERSION } from "./version";
export class Logger {
  static init() {
    if (import.meta.env.DR_SENTRY_DSN != "") {
      Sentry.init({
        dsn: import.meta.env.DR_SENTRY_DSN,
        release: `dr-mobile@${CURRENT_VERSION}`,
        environment: import.meta.env.DR_ENV,
        sampleRate: 1,
        autoSessionTracking: true,
        replaysOnErrorSampleRate: 1,
        replaysSessionSampleRate: 0,
        integrations: [
          Sentry.replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: false,
            blockAllMedia: false,
            stickySession: true,
            networkDetailAllowUrls: [
              "/VisualizeJS/25_6/Visualize.js.wasm",
              "/VisualizeJS/25_6/Visualize.js",
            ],
            networkRequestHeaders: ["X-Custom-Header"],
            networkResponseHeaders: ["X-Custom-Header"],
            networkCaptureBodies: false,
          }),
          //Sentry.replayCanvasIntegration(),
        ],
      });
    }

    // set log level
    log.setLevel(import.meta.env.DR_LOG_LEVEL);
  }
  static trace(message: string) {
    log.trace(message);
  }
  static debug(...args: any) {
    log.debug(...args);
  }
  static info(message: string) {
    log.info(message);
  }
  static warn(message: string) {
    log.warn(message);
  }
  static error(message: string) {
    log.error(message);
  }
  static identify() {
    if (!UserState.data) return;
    Sentry.setUser({ email: UserState.data.email });
  }
}
