import type { DRViewer } from "@/open-cloud/DRViewer";
import {
  type Handles,
  type HandleName,
  type Handle,
  HANDLE_NAMES,
  THEME,
  type SELECTED_ITEM_TYPE,
} from "./transform.type";
import { EntityBuilder } from "@/open-cloud/builders/EntityBuilder";
import Toolbox from "@/open-cloud/builders/ODAToolbox";
import { clone, findKey } from "lodash";
import { PhotoMarker } from "@/open-cloud/photoMarker";

export default class Grid {
  // @todo currently they look different depending on the device pixel density.
  //  We should read that info somewhere and compute accordingly.
  //  For now it is supposed to look okay on a tablet (main critical use)
  HANDLE_RADIUS = 10;
  // How far from the Grid is the Rotation handle
  ROTATION_HANDLE_DISTANCE = 50;
  // We allow the user to grab the handle if he clicks a bit on the side (near miss)
  CORNER_GRAB_RADIUS = 20;
  // the grids currently displayed

  viewer: DRViewer;
  points:
    | {
        [Key in HandleName]: VisualizeJS.Point3;
      }
    | null = null;
  handles: Handles = {};
  type: SELECTED_ITEM_TYPE;
  perimeterEntityId: VisualizeJS.OdTvEntityId | null = null;
  targetEntityIds: VisualizeJS.OdTvEntityId[];
  data: Record<string, any> & {
    transformMatrix?: VisualizeJS.Matrix3d;
  } = {};
  constructor(targets: VisualizeJS.OdTvEntityId[], viewer: DRViewer) {
    this.viewer = viewer;
    this.targetEntityIds = targets;
    const entId = targets[0];
    if (entId.getType() === 2) this.type = "INSERT";
    else if (EntityBuilder.isOnlyText(entId)) this.type = "ONLY_TEXT";
    else if (viewer.leaderBuilder.isLeader(entId)) this.type = "LEADER";
    else if (PhotoMarker.isPhotoMarker(entId)) this.type = "PHOTO_MARKER";
    else this.type = "ALL";
  }

  get visLib(): typeof VisualizeJS {
    return this.viewer.visLib();
  }

  get visViewer(): VisualizeJS.Viewer {
    return this.viewer.visViewer();
  }

  // Returns the handles center points
  getPoints(): {
    [Key in HandleName]: VisualizeJS.Point3;
  } {
    // Calculate points of the extent
    const ext = EntityBuilder.getAgnosticExtent(this.targetEntityIds[0]);
    const { center, min, max } = EntityBuilder.decodeExtent(ext);
    ext.delete();
    const rotationHandleOffset = this.viewer.toolbox.screenDistanceToWorld(
      this.ROTATION_HANDLE_DISTANCE
    );
    const dist = Math.sqrt(Math.pow(rotationHandleOffset, 2) / 2);
    return {
      topLeft: [min[0], max[1], 0],
      topRight: [max[0], max[1], 0],
      bottomLeft: [min[0], min[1], 0],
      bottomRight: [max[0], min[1], 0],
      topMiddle: [center[0], max[1], 0],
      rightMiddle: [max[0], center[1], 0],
      bottomMiddle: [center[0], min[1], 0],
      leftMiddle: [min[0], center[1], 0],
      center: center,
      rotation: [center[0], max[1] + rotationHandleOffset, 0],
      scale: [max[0] + dist, min[1] - dist, 0],
      leader: [max[0] + dist, center[1], 0],
    };
  }

  draw() {
    this.points = this.getPoints();
    // calculate radius depending from px to WCS
    const radius = this.viewer.toolbox.screenDistanceToWorld(
      this.HANDLE_RADIUS
    );
    // draw perimeter
    if (this.type != "ONLY_TEXT" && this.type != "LEADER") {
      this.drawPerimeter(this.points);
    }

    // Draw handles
    if (this.type === "ALL") {
      const clonePoints: Partial<typeof this.points> = clone(this.points);
      // don't draw leader handle
      clonePoints.leader = undefined;
      // In case there is an overlapping of handles deactivate and keep only center
      const diagonal = Toolbox.computeDistance2D(
        this.points.bottomRight,
        this.points.topLeft
      );

      if (diagonal < 4 * radius) {
        clonePoints.bottomLeft = undefined;
        clonePoints.bottomRight = undefined;
        clonePoints.topRight = undefined;
        clonePoints.topLeft = undefined;
      }

      const distanceY = Toolbox.computeDistance2D(
        this.points.bottomMiddle,
        this.points.topMiddle
      );
      if (distanceY < 4 * radius) {
        clonePoints.topMiddle = undefined;
        clonePoints.bottomMiddle = undefined;
      }

      const distanceX = Toolbox.computeDistance2D(
        this.points.rightMiddle,
        this.points.leftMiddle
      );

      if (distanceX < 4 * radius) {
        clonePoints.rightMiddle = undefined;
        clonePoints.leftMiddle = undefined;
      }

      this.drawHandles(clonePoints, radius);
    } else if (this.type === "PHOTO_MARKER") {
      this.drawHandles(
        {
          center: this.points.center,
          rotation: this.points.rotation,
        },
        radius
      );
    } else if (this.type === "ONLY_TEXT") {
      this.drawHandles(
        {
          center: this.points.center,
          leader: this.points.leader,
        },
        radius
      );
    } else if (this.type === "LEADER") {
      this.drawHandles(
        {
          center: this.points.center,
          leader: this.points.leader,
        },
        radius
      );
    } else if (this.type === "INSERT") {
      this.drawHandles(
        {
          center: this.points.center,
          rotation: this.points.rotation,
        },
        radius
      );
    }
  }

  drawHandles(
    points: { [Key in HandleName]?: VisualizeJS.Point3 },
    radius: number
  ) {
    for (const handleName of HANDLE_NAMES) {
      if (points[handleName] && this.points) {
        switch (handleName) {
          case "topRight":
          case "topLeft":
          case "bottomRight":
          case "bottomLeft":
            this.handles[handleName] = this.drawHandle(
              this.points[handleName],
              radius
            );
            break;
          case "bottomMiddle":
          case "leftMiddle":
          case "rightMiddle":
          case "topMiddle":
            this.handles[handleName] = this.drawHandle(
              this.points[handleName],
              0.8 * radius
            );
            break;
          case "center":
          case "rotation":
          case "scale":
            this.handles[handleName] = this.drawHandle(
              this.points[handleName],
              1.2 * radius,
              THEME.handleFillRGBAlt
            );
            break;
          case "leader":
            this.handles[handleName] = this.drawHandleWithArrow(
              this.points[handleName],
              1.2 * radius,
              THEME.handleFillRGBAlt
            );
        }
      }
    }
  }

  drawHandle(
    point: VisualizeJS.Point3,
    radius: number,
    fill = THEME.handleFillRGB,
    peri = THEME.handlePerimeter
  ): Handle {
    const mupModel = this.visViewer.getMarkupModel();
    const entId = mupModel.appendEntity("handle");
    EntityBuilder.appendCircleHandle(entId, point, radius, fill, peri);
    mupModel.delete();
    return {
      entity: entId,
      point,
    };
  }

  drawHandleWithArrow(
    point: VisualizeJS.Point3,
    radius: number,
    fill = THEME.handleFillRGB,
    peri = THEME.handlePerimeter,
    arrowFill = THEME.arrowFillRGB
  ): Handle {
    const handle = this.drawHandle(point, radius, fill, peri);
    EntityBuilder.appendTriangleHandle(
      handle.entity,
      point,
      radius * 0.6,
      arrowFill
    );
    return handle;
  }

  drawPerimeter(points: { [Key in HandleName]: VisualizeJS.Point3 }) {
    const mupModel = this.visViewer.getMarkupModel();
    this.perimeterEntityId = mupModel.appendEntity("grid_perimeter");
    mupModel.delete();
    const ent = this.perimeterEntityId.openObject();
    ent.setColor(...THEME.gridPerimeterRGB);
    const topCenter = Toolbox.computeCenter2D(points.topRight, points.topLeft);
    const poly = ent.appendPolyline([
      ...topCenter,
      ...points.topRight,
      ...points.bottomRight,
      ...points.bottomLeft,
      ...points.topLeft,
      ...topCenter,
      ...points.rotation,
    ]);
    poly.delete();
    ent.delete();
  }

  /**
   * check if a given point matches a handle
   * @param x
   * @param y
   */
  matchHandle(x: number, y: number): { handleName: HandleName } | undefined {
    const radius = this.viewer.toolbox.screenDistanceToWorld(
      this.CORNER_GRAB_RADIUS
    );
    const matchHandleName: HandleName | undefined = findKey(
      this.handles,
      (handle) => {
        if (!handle) return false;
        return Toolbox.computeDistance2D(handle.point, [x, y, 0]) <= radius;
      }
    );
    if (matchHandleName) {
      return {
        handleName: matchHandleName,
      };
    }
  }

  clean() {
    const mupModel = this.visViewer.getMarkupModel();

    if (this.perimeterEntityId) {
      mupModel.removeEntity(this.perimeterEntityId);
      this.perimeterEntityId?.delete();
      this.perimeterEntityId = null;
    }

    Object.values(this.handles).forEach((handle) => {
      mupModel.removeEntity(handle.entity);
      handle.entity.delete();
    });
    this.handles = {};
    this.points = null;
  }

  setVisible(visible: boolean) {
    if (this.perimeterEntityId) {
      this.viewer.entityBuilder.setVisible(this.perimeterEntityId, visible);
    }
    Object.values(this.handles).forEach((handle) => {
      this.viewer.entityBuilder.setVisible(handle.entity, visible);
    });
  }
  // a transform needs a point of reference that does not move in the transformation
  // this method returns the ref handle
  static matchScaleHandler(
    handleName: HandleName
  ): { refHandle: HandleName; axles?: { x?: boolean; y?: boolean } } | null {
    switch (handleName) {
      case "topRight":
        return { refHandle: "bottomLeft" };
      case "bottomRight":
        return { refHandle: "topLeft" };
      case "bottomLeft":
        return { refHandle: "topRight" };
      case "topLeft":
        return { refHandle: "bottomRight" };
      case "bottomMiddle":
        return { refHandle: "topMiddle", axles: { y: true } };
      case "topMiddle":
        return { refHandle: "bottomMiddle", axles: { y: true } };
      case "rightMiddle":
        return { refHandle: "leftMiddle", axles: { x: true } };
      case "leftMiddle":
        return { refHandle: "rightMiddle", axles: { x: true } };
      default:
        return null;
    }
  }
}
